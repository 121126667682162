'use client'

import { Suspense } from 'react'

import { Header, Footer } from '@/components'

import Page404 from '@/components/Page404/Page404'

export default function Error() {
  return (
    <>
      <Suspense>
        <Header hiddenMenuDesktop={true} />
      </Suspense>
      <Page404 />
      <Footer />
    </>
  )
}
